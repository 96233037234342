const Module = () => import("./module.vue");
const Home = () => import("./views/Home.vue");
const Pincode = () => import("./views/Pincode");
const EditDetails = () => import("./views/EditDetails.vue");
const ConfirmDetails = () => import("./views/ConfirmDetails.vue");
const Success = () => import("./views/Success");
const Failure = () => import("./views/Failure");
const TermsConditions = () => import("./views/TermsConditions");
const PrivacyPolicy = () => import("./views/PrivacyPolicy");
const sitemap = () => import("./views/Sitemap");
const bikes = () => import("./views/bikes");
const checkApplication = () => import("./views/track/checkApplication");
const trackApplication = () => import("./views/track/track");
const acknowledge = () => import("./views/pdf/acknowledge");

const moduleRoute = {
  path: "/finance",
  component: Module,
  children: [
    {
      path: "/",
      component: Home,
      meta: {
        showNavbar: true,
        navbarColor: "#fffff",
      },
    },
    {
      path: "/s/:id",
      beforeEnter: (to, from, next) => {
        next("/finance/success/" + to.params.id);
      },
    },
    {
      path: "download",
      component: acknowledge,
    },
    {
      path: "pincode",
      component: Pincode,
    },
    {
      path: "editdetails",
      component: EditDetails,
      meta: {
        showBike: true,
        showNavbar: true,
        fixedNavbar: false,
      },
    },
    {
      path: "confirmdetails",
      component: ConfirmDetails,
      meta: {
        showBike: true,
        showNavbar: true,
      },
    },
    {
      path: "success/:id",
      component: Success,
      meta: {
        showNavbar: true,
        showFooter: true,
      },
    },
    {
      path: "failure",
      component: Failure,
    },
    {
      path: "TermsConditions",
      component: TermsConditions,
    },
    {
      path: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "Sitemap",
      component: sitemap,
    },
    {
      path: "view-bike",
      component: bikes,
    },
    {
      path: "check-application",
      component: checkApplication,
    },
    {
      path: "track-application/:id",
      component: trackApplication,
    },
    {
      path: "/bike-loan-calculator",
      component: () => import("./views/BikeLoanCalculator.vue"),
    },
    {
      path: "/partner/:partnerId",
      name: "Partner Home",
      component: () => import("./views/partners/partnerHome"),
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
