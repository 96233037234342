import axios from "axios";
var baseDomain = "https://api.on-track.in";
// var baseDomainv2 = "https://ontrack-backend-express-v1.herokuapp.com";
var baseDomainv2 = "https://glacier.on-track.in";

var baseURL = `${baseDomain}/api`;
var baseURLv2 = `${baseDomainv2}/api`;
// var refCount = 0
// var isLoading = false

export var HTTP = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: "Basic YWRtaW5BdXRoOnZpc2h1QDE5OTI="
  }
});
export var HTTP2 = axios.create({
  baseURL: baseURLv2,
  headers: {
    // Authorization: 'Basic ' + localStorage.getItem('user-token')
  }
});
export var HTTPwithAuth = axios.create({
  baseURL: baseURLv2,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("user-token")
  }
});
export var server = axios.create({
  baseURL: "http://159.89.164.225:45698/api/"
});

// Call goobal loading interceptor

// export default async function loadingInitialize () {
//   HTTP2.interceptors.request.use((config) => {
//     setLoading(true)
//     return config
//   }, (error) => {
//     setLoading(false)
//     return Promise.reject(error)
//   })

//   HTTP2.interceptors.response.use((response) => {
//     setLoading(false)
//     return response
//   }, (error) => {
//     setLoading(false)
//     return Promise.reject(error)
//   })
// }

// function setLoading (value) {
//   store.state.globalLoading = value
// }
