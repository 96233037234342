import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    vehicleSelected: JSON.parse(localStorage.getItem("selectionData")) || null,
  },
  mutations: {
    setVehicleSelected(state, data) {
      state.vehicleSelected = data;
    },
  },
  actions: {
    setVehicleSelected({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("selectionData", JSON.stringify(payload));
          commit("setVehicleSelected", payload);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  modules: {},
});
