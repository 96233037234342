<template>
  <v-app>
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {};
</script>
<style>
.scroll-container {
  display: flex;
  overflow: scroll;
}
</style>
