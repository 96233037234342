import Vue from "vue";
import Vuelidate from "vuelidate";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as axiosInstances from "./plugins/axios";
import financeModule from "./modules/finance";

import { registerModules } from "./register-modules";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from "vue-browser-geolocation";
import VueMeta from "vue-meta";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCAUroK-REFp2YziFMJSr0G4eN9le5LWRM",
  authDomain: "ontrack-finance.firebaseapp.com",
  projectId: "ontrack-finance",
  storageBucket: "ontrack-finance.appspot.com",
  messagingSenderId: "80003504770",
  appId: "1:80003504770:web:071af3e82a2f9f1d52729b",
  measurementId: "G-3V8TD767PC",
};
firebase.initializeApp(firebaseConfig);
Vue.use(VueBottomSheet);
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);

Vue.use(VueMeta, {
  keyName: "head",
});

Vue.use(VueGtag, {
  config: { id: "G-3V8TD767PC" },
});
Vue.use(VueGeolocation);
registerModules({
  finance: financeModule,
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC-eQsx6q7hHySLEqtnyKqUv1lTmB5pYLw",
  },
  installComponents: false,
});

Vue.component("GmapMap", VueGoogleMaps.Map);
Vue.component("GmapMarker", VueGoogleMaps.Marker);
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.prototype.$api = axiosInstances;
Vue.filter("currency", function(value) {
  return "₹" + parseFloat(value).toLocaleString();
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
