import * as axiosInstances from "../../../plugins/axios";

export default {
  state: {
    loading_api: false,
    globalMessage: null,
  },
  mutations: {
    loading(state, value) {
      state.loading_api = value;
    },
  },
  actions: {
    sendOtp({ commit }, payload) {
      // var customerId = this.getters.userData.id
      commit("loading", true);
      return new Promise((resolve, reject) => {
        axiosInstances.HTTP2.post("profile/v2/user/try", payload)
          .then((response) => {
            window.console.log(response);
            commit("loading", false);
            resolve(response.data);
          })
          .catch((e) => {
            commit("loading", false);
            reject(e);
          });
      });
    },
    verifyOtp({ commit }, payload) {
      // var customerId = this.getters.userData.id
      commit("loading", true);
      return new Promise((resolve, reject) => {
        axiosInstances.HTTP2.post("/otp/verifyOtp", payload)
          .then((response) => {
            window.console.log(response);
            commit("loading", false);
            resolve(response.data);
          })
          .catch((e) => {
            commit("loading", false);
            reject(e);
          });
      });
    },
    checkLoanStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosInstances.HTTP2.get(
          "/finance/check/loan/prod/" + payload.applicationId
        )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            commit("loading", false);
          });
      });
    },
    updatePendingLoan({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosInstances.HTTP2.post(
          "/finance/update/loan/prod/" + payload.applicationId
        )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            commit("loading", false);
          });
      });
    },
  },
  modules: {},
};
