import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      showApply: true,
      showNavbar: true,
      showFooter: true,
      navbarColor: "#f0f0f0",
      themeColor: "#ffffff",
    },
  },

  {
    path: "/bajaj-finance",
    name: "Bajaj",
    component: () => import("../components/appRootMainPage"),
  },
  {
    path: "/bajaj-finance/form",
    name: "Bajaj",
    component: () => import("../components/loanForm"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
